
h1 {
    text-align: center;
}
h2 {
    text-align: left;
    color: white;
}
.privacy {
    padding: 50px 200px;
}
.toplogo {
    max-width: 30%;
}
.privacy {
    padding: 0px 15vw;
}

