body {
  font-family: 'Manrope', sans-serif;
  margin: 0;
  background-color: #181818;
  color: white;
  overflow-x: hidden;
}

h1, h2, h3 {
  text-align: center;
  text-transform: uppercase;
}

h1 {
  font-family: 'Bayon';
  letter-spacing: 0.1em;
}

h2, h3 {
  font-family: 'Comfortaa', cursive;
  color: #FCAD01;
}

a {
  color: #ffffff;
  transition: color 0.3s;
  text-decoration: none;
  font-size: 0.9em;
}

a:hover {
  color: #FCAD01;
}

nav {
  background-color: #181818;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  padding: 10px 0;
  position: sticky;
  top: 0;
  z-index: 1000;
}

nav a {
  margin: 0 10px;
  text-decoration: none;
  color: white;
  transition: color 0.3s;
  font-family: 'Bayon';
}

nav a:hover {
  color: #FCAD01;
}

.full-page-header {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgb(57,57,57);
  background: radial-gradient(circle, rgba(57,57,57,1) 0%, rgba(24,24,24,1) 100%);
}

.lefthex, .righthex {
  position: absolute;
  top: 0;
  opacity: 20%;
}

.lefthex {
  left: 0;
}

.righthex {
  right: 0;
}

.lefthex2, .righthex2 {
  position: absolute;
  top: -100px;
}

.lefthex2 {
  left: -100px;
}

.righthex2 {
  right: -100px;
}

section {
  padding: 50px 20px;
}

#portfolio {
  text-align: center;
}
.portfolio-content {
  display: grid;
  gap: 20px;
}
.portfolio-item, .portfolio-item-large {
  position: relative;
}
.portfolio-item img{
  border-radius: 10px;
}
.portfolio-item-large img {
  border-radius: 10px;
}
.image-section {
  position: absolute;
}
.text-section {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(24, 24, 24, 0.7); 
  color: white;
  opacity: 0;
  transition: opacity 0.3s ease;
}
.portfolio-item:hover .text-section, .portfolio-item-large:hover .text-section {
  opacity: 1;
}
.load-more {
  padding: 20px;
  background-color: #FCAD01;
  font-family: 'Bayon';
  color: white;
  font-size: 1.5em;
  border: 0px;
  border-radius: 4px;
  margin: 20px 0 0 0;
  letter-spacing: 0.1em;
}
.col h3 {
  text-align: left;
}
.services-1 {
  display: grid;
}
.services-2 {
  display: grid;
}
.services-1 ul {
  line-height: 1.7em;
}

.services-2 img, #contact .contact-body .contact-details img {
  margin: auto;
  display: flex;
}

.services-2 h2, .contact-details h2 {
  text-align: center;
  color: white;
}

.services-2 p, .contact-details p {
  text-align: center;
  color: #707070;
}

#contact {
  padding:  0px 0px 0px 0px;
}
.contact-body {
  display: grid;
}
.contact-details button {
  margin: auto;
  display: flex;
  border: 0px;
  background-color: #FCAD01;
  border-radius: 5px;
  padding: 10px;
  font-family: Comfortaa;
  font-size: 1em;
  font-weight: 800;
  cursor: pointer;
}

.contact-details button:hover {
  background-color: #dd7d00;
}
.contact-image img {
  max-width: 100%;
}
#contact .contact-body .contact-details img {
  color: #FCAD01;
  margin: auto;
  display: flex;
}

footer {
  background-color: #111111;
  padding: 0 20px 20px;
  text-align: center;
}

footer img {
  max-width: 10%;
  display: flex;
  margin: auto;
}

footer p {
  font-size: 0.9em;
}

/* Mobile styles */
@media (max-width: 768px) {
  h1 { font-size: 1.6em; }
  h2 { font-size: 1.3em; }
  h3 { font-size: 1em; }
  nav a { font-size: 1.1em; }
  #about, #portfolio { padding: 20px 10px; }
  .about-header h1, .portfolio-header h1 { padding: 0px 10px; }
  .about-content { margin-top: 20px; font-size: 1em; }
  .about-content p { margin: 20px; }
  #about img { max-width: 100%; height: auto; }
  .toplogo { max-width: 30%; }
  .lefthex, .righthex { max-width: 50%; }
  .lefthex2 { width: 20%; }
  .righthex2 { width: 20%; }
  .full-page-header { height: 20vh; }
  .image-section {position: static;}
  .text-section {position: static; opacity: 1;}
  .portfolio-content { grid-template-columns: repeat(1, 1fr); gap: 20px; }
  .portfolio-item {width: 60vw; height: auto; margin-left: auto; margin-right: auto;}
  .portfolio-item-large {width: 60vw; height: auto; margin-left: auto; margin-right: auto;}
  .portfolio-item img { max-width: 100%; }
  .portfolio-item-large img { max-width: 100%; }
  .portfolio-content h1, h3 {line-height: 0.5em; margin-block-start: 0.5em; margin-block-end: 0.5em;} 
  .portfolio-content p {margin-block-start: 0.5em; margin-block-end: 0.5em;}
  .services-1 { padding: 0 20px; grid-template-columns: repeat(2, 1fr); gap: 0px; }
  .services-2 { padding: 0 20px; margin-top: 10px; grid-template-columns: repeat(1, 1fr); gap: 30px; }
  .services-2 img { max-width: 15%; }
  .contact-body { grid-template-columns: repeat(1, 1fr); }
  #contact .contact-body .contact-details img {max-width: 30px; }
}

/* Tablet styles */
@media (min-width: 768px) and (max-width: 1300px) {
  h1 { font-size: 1.6em; }
  h2 { font-size: 1.3em; }
  h3 { font-size: 1em; }
  nav a { font-size: 1.5em; }
  #about, #portfolio { padding: 20px 10vw; }
  .about-header h1, .portfolio-header h1 { padding: 0px 10px; }
  .about-content { margin-top: 20px; font-size: 1em; }
  .about-content p { margin: 20px; }
  #about img { max-width: 100%; height: auto; }
  .toplogo { max-width: 30%; }
  .lefthex, .righthex { max-width: 40%; }
  .lefthex2 { width: 60%; }
  .righthex2 { width: 60%; }
  .full-page-header { height: 40vh; }
  .image-section {position: static;}
  .text-section {position: static; opacity: 1;}
  .portfolio-content { grid-template-columns: repeat(2, 1fr); gap: 20px; }
  .portfolio-item {width: 40vw; height:auto;}
  .portfolio-item-large {width: 40vw; height: auto; }
  .portfolio-item img { max-width: 100%; }
  .portfolio-item-large img { max-width: 100%; }
  .portfolio-content h1, h3 {line-height: 0.5em; margin-block-start: 0.5em; margin-block-end: 0.5em;} 
  .portfolio-content p {margin-block-start: 0.5em; margin-block-end: 0.5em;} 
  .services-1 { padding: 0 20px; grid-template-columns: repeat(2, 1fr); gap: 0px; }
  .services-2 { padding: 0 20px; margin-top: 10px; grid-template-columns: repeat(3, 1fr); gap: 30px; }
  .services-2 img { max-width: 15%; }
  .contact-body { grid-template-columns: repeat(1, 1fr); }
  #contact .contact-body .contact-details img {max-width: 30px; }
}

/* Desktop styles */
@media (min-width: 1301px) {
  h1 { font-size: 2em; }
  h2 { font-size: 1.3em; }
  h3 { font-size: 1em; line-height: 1em;}
  p {font-size: 1em;}
  nav a { font-size: 1.7em; }
  #about, #portfolio { padding: 50px 20vw 0px 20vw; }
  .about-header h1 { padding: 0px 1vw; }
  .about-content { display: flex; align-items: start; margin-top: 20px;}
  .about-content p { flex: 1; margin-right: 1vw; font-size: 1.3vw;}
  #about img { max-width: 50%; min-width: 400px;}
  .toplogo { max-width: 30%; }
  .lefthex, .righthex { max-width: 30%; }
  .lefthex2 { width: 50%; }
  .righthex2 { width: 50%; }
  .portfolio-content { grid-template-columns: repeat(3, 1fr); gap: 20px; grid-auto-flow: dense;}
  .portfolio-item {width: 20vw; height: 20vw;}
  .portfolio-item img { max-width: 100%; }
  .portfolio-item-large img { max-width: 100%; object-fit: cover;}
  .portfolio-item-large { width: 41.5vw; height: 0;padding-bottom: 100%; grid-row: span 2; grid-column: span 2; position: relative;}
  .portfolio-content h1, h3 {line-height: 0.5em;} 
  .services-1 { padding: 0 20vw; grid-template-columns: repeat(2, 1fr); gap: 0px; }
  .services-1 h3{line-height: normal;}
  .services-2 { padding: 0 20vw; margin-top: 50px; grid-template-columns: repeat(3, 1fr); gap: 30px; }
  .services-2 img { max-width: 20%; }
  .contact-body { grid-template-columns: repeat(2, 1fr); }
  #contact .contact-body .contact-details img { max-width: 5%;}
  .contact-details h2{font-size: 1.2vw;}
  .contact-details p{font-size: 1vw;}
  .contact-details button {font-size: 1vw;}
}
